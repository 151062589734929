<template>
  <v-container name="jobsList" fluid>
    <v-row class="mb-1">
      <v-col cols="auto">
        <h1 class="text-h5 mt-1">รายการงานเดินรถ</h1>
      </v-col>

      <v-spacer></v-spacer>

      <v-col cols="12" sm="4">
        <JobSearchingInput
          :key="componentRefreshCounter"
          ref="JobSearchingInputRef"
          :tableLoading="tableLoading"
          :disabled="tableLoading"
          @searchItemChange="getSearchItem"
        />
      </v-col>

      <v-col cols="auto">
        <v-menu
          v-model="isShowMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          :nudge-bottom="10"
          :nudge-left="100"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#D5DBDB"
              light
              large
              v-bind="attrs"
              v-on="on"
              elevation="0"
              outlined
              :disabled="tableLoading"
              :style="{
                backgroundColor: 'white !important',
              }"
            >
              <v-icon left color="#4D5656"> mdi-filter </v-icon>
              <div class="normal-button-text">{{ "ตัวกรอง" }}</div>
            </v-btn>
          </template>
          <JobsFilterGroup
            :key="componentRefreshCounter"
            :disabled="tableLoading"
            @close-menu="isShowMenu = false"
            @returnFiltedData="getfilterItem"
          />
        </v-menu>
      </v-col>
      <v-col cols="auto" v-if="permList.jobs_crud || permList.admin_all">
        <PrimaryButton
          :large="true"
          icon="mdi-plus"
          :disabled="tableLoading"
          @on-click="handleAddClick()"
        >
          เพิ่มข้อมูล
        </PrimaryButton>
      </v-col>
    </v-row>
    <v-data-table
      :headers="tableHeaders"
      :items="tableRows"
      :items-per-page="queryOptionsTable.limit"
      :page="queryOptionsTable.page"
      :loading="tableLoading"
      :server-items-length="serverItemsLength"
      :footer-props="dataTableFooterProps"
      :no-data-text="dataTableNoDataText"
      :loading-text="dataTableLoadingText"
      @update:page="tablePageHandler"
      @update:items-per-page="tableItemPerPageHandler"
      class="app-datatable"
    >
      <template v-slot:top>
        <div class="app-table-list-identity pt-4 px-4">
          <div class="d-flex">
            <div class="mr-1 mb-4">
              <JobContractSelectorInput
                style="width: 250px"
                ref="JobContractSelectorInputRef"
                @searchItemChange="tableContractChangeHandler"
                :value="selectedContract"
                placeholder="สัญญา"
                :required="false"
                :clearable="true"
                :disabled="tableLoading"
                @update-items="JobContractSelectorInputItems"
                :key="componentRefreshCounter"
              />
            </div>
            <div style="width: 250px" class="mr-1">
              <v-autocomplete
                :key="updateRouteKey"
                :items="routeItemList"
                :loading="routeLoading"
                :value="selectedRouteItem"
                placeholder="เส้นทาง"
                append-icon=""
                clearable
                return-object
                filled
                dense
                hide-no-data
                hide-selected
                hide-details
                flat
                outlined
                :disabled="tableLoading"
                class="app-custom-input"
                @change="tableContractRouteItemChangeHandler"
                item-text="name"
                item-value="_id"
              ></v-autocomplete>
            </div>
            <div style="width: 250px">
              <v-select
                class="app-custom-input"
                hide-details
                label="กลุ่มเดินรถ"
                placeholder="เลือก"
                outlined
                dense
                clearable
                v-model="driverGroup"
                :items="driverGroupList"
                item-text="name"
                item-value="_id"
                :append-icon="''"
                :disabled="tableLoading"
                @change="tableGroupChangeHandler"
                return-object
              ></v-select>
            </div>

            <v-divider class="mx-2" vertical></v-divider>

            <div class="mr-2">
              <CustomDateTypingPickerFormInput
                ref="date"
                v-model="datePicker"
                title=""
                :label="`วันที่วิ่งงาน`"
                placeholder="วัน/เดือน/ปี"
                :expendMargin="false"
                :isRequired="false"
                :disabled="tableLoading"
                :titleBottomMargin="false"
                @handle-update-input="tableDatePickerChangeHandler"
                dataInputName="startDate"
              />
            </div>

            <div class="d-flex">
              <CustomTimePickerInput
                title=""
                :value="inputStartTime"
                :small="true"
                :label="`เริ่มงาน`"
                :input-view-bottom-margin="false"
                :expend-margin="false"
                :isRequired="false"
                :hide-detail="true"
                :is-dark="true"
                :disabled="tableLoading"
                @input="tableStartTimeChangeHandler"
                class="mr-1"
              />
              <CustomTimePickerInput
                title=""
                :value="inputEndTime"
                :small="true"
                :label="`จบงาน`"
                :input-view-bottom-margin="false"
                :expend-margin="false"
                :isRequired="false"
                :hide-detail="true"
                :is-dark="true"
                :locationStart="true"
                :disabled="tableLoading"
                @input="tableEndTimeChangeHandler"
                class="mr-1"
              />
              <v-checkbox
                dense
                style="min-width: 60px"
                class="pt-1 ml-2"
                label="วันนี้"
                :readonly="tableLoading"
                v-model="isToday"
                @change="handleToday()"
                hide-details
              ></v-checkbox>
            </div>
            <!-- 
                    <v-select
                    class="app-custom-input"
                    :items="contractRouteTitleList"
                    v-model="contractRoute"
                    label="สัญญา/เส้นทาง"
                    placeholder="เลือก"
                    item-value="key"
                    item-text="value"
                    outlined
                    dense
                    filled
                    clearable
                    hide-details
                    return-object
                    :append-icon="''"
                    @change="tableContractRouteChangeHandler"
                  ></v-select>
             -->
          </div>
          <!-- <v-col cols="2">
              <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                  @click="handleSchedule(tableRows, true)"
                  class="mx-3"
                  color="primary"
                  elevation="0"
                  light
                  large
                  outlined
                  ><v-icon>mdi-bird</v-icon></v-btn
                >
                <NormalButton icon="mdi-refresh" @on-click="reset()">
                  รีเซ็ต
                </NormalButton>
              </div>
            </v-col> -->

          <div class="d-flex justify-space-between">
            <div class="d-flex">
              <div style="width: 330px">
                <v-select
                  class="app-custom-input"
                  :items="sortList"
                  label="เรียงลำดับโดย"
                  dense
                  outlined
                  :disabled="tableLoading"
                  v-model="sortVal"
                  @change="tableSortHandler"
                  hide-details
                ></v-select>
              </div>

              <div>
                <v-divider class="mx-3 mb-6" vertical></v-divider>
              </div>

              <v-checkbox
                dense
                class="pl-2 pt-1"
                label="สแกนหน้าไม่ผ่าน"
                :disabled="tableLoading"
                v-model="checkboxData.scanface"
                @change="changeValueOfCheckboxStatus()"
                hide-details
              ></v-checkbox>
              <v-checkbox
                dense
                class="pl-2 pt-1"
                label="รถไม่ถูกต้อง"
                :disabled="tableLoading"
                v-model="checkboxData.samevehicle"
                @change="changeValueOfCheckboxStatus()"
                hide-details
              ></v-checkbox>

              <v-checkbox
                dense
                class="pl-2 pt-1"
                label="ไม่ได้กำหนดความพร้อม"
                :disabled="tableLoading"
                v-model="checkboxData.driverstatus"
                @change="changeValueOfCheckboxStatus()"
                hide-details
              ></v-checkbox>
            </div>

            <div class="d-flex">
              <v-spacer></v-spacer>
              <v-btn
                @click="handleSchedule(tableRows, true)"
                class="mx-3"
                color="primary"
                elevation="0"
                light
                large
                outlined
                :disabled="tableLoading"
                ><v-icon>mdi-bird</v-icon></v-btn
              >
              <NormalButton
                icon="mdi-refresh"
                @on-click="reset()"
                :disabled="tableLoading"
              >
                รีเซ็ต
              </NormalButton>
            </div>
          </div>

          <div class="d-flex mt-1">
            <JobsFilterStatus
              :key="componentRefreshCounter"
              :value="statusFilterValue"
              @input="processStatus"
              :disabled="tableLoading"
              class="pl-3"
            />
          </div>
        </div>
      </template>
      <template v-slot:[`item.jobCode`]="{ item }">
        <p class="mb-0">{{ item.jobCode }}</p>
        <p v-if="item.newEventAccess" class="red--text text-app-normal">
          มีแนวโน้มปิดแอปฯ
        </p>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <!-- for assigner -->
        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-title
              v-if="
                permList.jobs_info || permList.jobs_crud || permList.admin_all
              "
              class="text-app-title font-weight-bold primary--text"
              style="cursor: pointer"
              @click="handleEditClick(item._id)"
            >
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-title v-else class="text-app-title font-weight-bold">
              {{ item.name }}
            </v-list-item-title>
            <p class="text-app-normal">
              {{ item.contract == "-" ? "-" : item.contract.value }}
            </p>
            <p class="text-app-normal font-weight-bold">
              โดย: {{ `${item.assigner.dpNameTh}` }}
            </p>
            <p class="text-app-normal mb-0">
              {{ `${item.assigner.dpPhoneNumeber}` }}
            </p>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:[`item.driver`]="{ item }">
        <v-list-item two-line class="px-0">
          <v-list-item-avatar size="30" v-if="item.driver !== null">
            <v-img
              :src="
                item.driver === null
                  ? defaultPFImage
                  : item.driver.profileImage === null
                  ? defaultPFImage
                  : item.driver.profileImage
              "
            >
            </v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-app-title font-weight-bold">
              {{ `${item.driver ? item.driver.dpNameTh : "-"}` }}
            </v-list-item-title>

            <div v-if="item.driver !== null">
              <p class="d-inline">ใบหน้า:</p>

              <div
                class="d-inline"
                v-if="permList.admin_all || permList.jobs_crud"
              >
                <span
                  v-if="item.driverFaceMatched"
                  :class="item.driverFaceMatchedColor"
                >
                  {{ item.driverFaceMatchedText }}
                </span>
                <span v-else>
                  <span
                    v-if="
                      item.status === 'CONFIRM' ||
                      item.status === 'PROCESS' ||
                      item.status === 'SUCCESS'
                    "
                  >
                    <a
                      :class="`text-decoration-underline ${item.driverFaceMatchedColor}`"
                      @click="openCheckFaceDialog(item)"
                      v-text="item.driverFaceMatchedText"
                    >
                    </a>
                  </span>
                  <span
                    class="text-app-normal"
                    v-else
                    v-text="item.driverFaceMatchedText"
                  >
                  </span>
                </span>
              </div>

              <div class="d-inline" v-else>
                <span :class="item.driverFaceMatchedColor">
                  {{ item.driverFaceMatchedText }}
                </span>
              </div>
              <!--  -->
            </div>
            <div v-if="item.driver !== null">
              <p class="d-inline">ความพร้อม:</p>

              <div
                class="d-inline"
                v-if="permList.admin_all || permList.jobs_crud"
              >
                <span
                  v-if="item.driverStatus"
                  :class="getDriverStatusColor(item.driverStatus)"
                >
                  {{ getDriverStatusText(item.driverStatus) }}
                </span>
                <span v-else>
                  <span
                    v-if="
                      item.status === 'CONFIRM' ||
                      item.status === 'PROCESS' ||
                      item.status === 'SUCCESS'
                    "
                  >
                    <a
                      :class="`text-decoration-underline ${getDriverStatusColor(
                        item.driverStatus
                      )}`"
                      @click="openCheckFaceDialog(item)"
                      v-text="getDriverStatusText(item.driverStatus)"
                    >
                    </a>
                  </span>
                  <span
                    class="text-app-normal"
                    v-else
                    v-text="getDriverStatusText(item.driverStatus)"
                  >
                  </span>
                </span>
              </div>

              <div class="d-inline" v-else>
                <span :class="getDriverStatusColor(item.driverStatus)">
                  {{ getDriverStatusText(item.driverStatus) }}
                </span>
              </div>
            </div>
            <span class="text-app-normal">
              {{ `${item.driver ? item.driver.dpPhoneNumeber : ""}` }}
            </span>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:[`item.vehicle`]="{ item }">
        <div class="d-flex" v-if="item.vehicleMatched.status !== -1">
          <div
            class="text-left flex-grow-1"
            v-if="
              item.vehicle &&
              (item.vehicleMatched.status === 1 ||
                item.vehicleMatched.status >= 3)
            "
          >
            <!--  1 select this car 3 has conflict-->
            <p class="text-app-normal text--darken-3 mb-0">
              {{ item.vehicleMatched.selectVehicleClassText }}
            </p>
            <p
              v-if="
                item.vehicleMatched.status === 1 ||
                item.vehicleMatched.status === 3
              "
              :class="`ma-0 text-app-title  ${item.vehicleMatched.selectVehicleClassName}`"
            >
              {{ item.vehicle ? item.vehicle.licenseNumber : "-" }}
            </p>

            <div v-if="item.vehicleMatched.status === 5">
              <a
                v-if="permList.jobs_crud || permList.admin_all"
                @click="openVerifyVehicle(item)"
                :class="`ma-0 text-app-title  text-decoration-underline ${item.vehicleMatched.selectVehicleClassName}`"
              >
                {{ item.vehicle ? item.vehicle.licenseNumber : "-" }}
              </a>

              <span
                v-else
                :class="`ma-0 text-app-title ${item.vehicleMatched.selectVehicleClassName}`"
              >
                {{ item.vehicle ? item.vehicle.licenseNumber : "-" }}
              </span>
            </div>

            <p class="ma-0 text-app-normal">{{ item.vehicle.type }}</p>
          </div>
          <div v-if="item.vehicle === null" class="text-left flex-grow-1">
            <p class="text-app-normal text--darken-3 mb-0">ระบบ</p>
            <p :class="`ma-0 text-app-title`">ไม่ได้เลือกรถ</p>
            <p class="ma-0 text-app-normal">-</p>
          </div>
          <!--  อห -->
          <v-divider
            vertical
            inset
            class="mx-3"
            v-if="item.vehicleMatched.status > 3"
          >
          </v-divider>
          <!--  -->

          <div
            class="text-left flex-grow-1"
            v-if="
              item.actualVehicle &&
              (item.vehicleMatched.status === 2 ||
                item.vehicleMatched.status >= 4)
            "
          >
            <p class="text-app-normal text--darken-3 mb-0">
              {{ item.vehicleMatched.actualVehicleClassText }}
            </p>
            <p
              v-if="
                item.vehicleMatched.status === 2 ||
                item.vehicleMatched.status === 4
              "
              :class="`ma-0 text-app-title  ${item.vehicleMatched.actualVehicleClassName}`"
            >
              {{ item.actualVehicle ? item.actualVehicle.licenseNumber : "-" }}
            </p>

            <div v-if="item.vehicleMatched.status === 5">
              <a
                v-if="permList.jobs_crud || permList.admin_all"
                @click="openVerifyVehicle(item)"
                :class="`ma-0 text-app-title  text-decoration-underline ${item.vehicleMatched.actualVehicleClassName}`"
              >
                {{
                  item.actualVehicle ? item.actualVehicle.licenseNumber : "-"
                }}
              </a>

              <span
                v-else
                :class="`ma-0 text-app-title ${item.vehicleMatched.selectVehicleClassName}`"
              >
                {{
                  item.actualVehicle ? item.actualVehicle.licenseNumber : "-"
                }}
              </span>
            </div>

            <p class="ma-0 text-app-normal">{{ item.actualVehicle.type }}</p>
          </div>
        </div>
        <div v-else>-</div>
      </template>

      <template v-slot:[`item.confirmDate`]="{ item }">
        <v-list-item dense two-line class="px-0">
          <v-list-item-content>
            <v-row no-gutters>
              <v-col align="left" class="ml-1">
                <span class="text-app-normal text--darken-3">ระบบ</span>
                <v-list-item-title
                  v-if="item.confirmDate"
                  class="font-weight-bold text--darken-3"
                >
                  {{ item.confirmDate }}
                </v-list-item-title>
                <v-list-item-title v-else class="text-app-normal text--darken-3"
                  >-</v-list-item-title
                >
              </v-col>
            </v-row>
            <v-divider />
            <v-row no-gutters class="mt-1">
              <v-col align="left" class="ml-1">
                <v-list-item-title
                  v-if="item.actualConfirmDate"
                  class="font-weight-bold"
                >
                  {{ item.actualConfirmDate }}
                </v-list-item-title>
                <v-list-item-title v-else class="text-app-normal"
                  >-</v-list-item-title
                >

                <span class="text-app-normal">ยืนยันจริง</span>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:[`item.startDate`]="{ item }">
        <v-list-item dense two-line class="px-0">
          <v-list-item-content>
            <v-row no-gutters>
              <v-col align="left" class="ml-1">
                <span class="text-app-normal">ระบบ</span
                ><v-list-item-title
                  v-if="item.startDate !== '-'"
                  class="font-weight-bold"
                >
                  {{ item.startDate }}
                </v-list-item-title>
                <v-list-item-title v-else class="text-app-normal"
                  >-</v-list-item-title
                >
              </v-col>
            </v-row>
            <v-divider />
            <v-row no-gutters class="mt-1">
              <v-col align="left" class="ml-1">
                <v-list-item-title
                  v-if="item.actualStartDate"
                  class="font-weight-bold"
                >
                  {{ item.actualStartDate }}
                </v-list-item-title>
                <v-list-item-title v-else class="text-app-normal"
                  >-</v-list-item-title
                >

                <span class="text-app-normal">เริ่มงานจริง</span>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:[`item.endDate`]="{ item }">
        <v-list-item dense two-line class="px-0">
          <v-list-item-content>
            <v-row no-gutters>
              <v-col align="left" class="ml-1">
                <span class="text-app-normal">ระบบ</span
                ><v-list-item-title
                  v-if="item.endDate"
                  class="font-weight-bold"
                >
                  {{ item.endDate }}
                </v-list-item-title>
                <v-list-item-title v-else class="text-app-normal"
                  >-</v-list-item-title
                >
              </v-col>
            </v-row>
            <v-divider />
            <v-row no-gutters class="mt-1">
              <v-col align="left" class="ml-1">
                <v-list-item-title
                  v-if="item.actualEndDate"
                  class="font-weight-bold"
                >
                  {{ item.actualEndDate }}
                </v-list-item-title>
                <v-list-item-title v-else class="text-app-normal"
                  >-</v-list-item-title
                >

                <span class="text-app-normal">จบงานจริง</span>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="text-center">
          <!--  -->
          <v-menu
            offset-y
            v-if="isPermissionCanEdit"
            :value="item.statusOpen"
            @input="getStatusListMenuChange"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="jobsStatusColor(item.status)"
                elevation="0"
                class="text-app-title"
                small
                :loading="item.statusLoading"
                :disabled="statusDisabledAll"
                @click="getStatusListHandler(item)"
              >
                {{ jobsStatusText(item.status) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(statusItem, index) in currentStatusList"
                :key="`sitem-${index}`"
                @click="selectStatusListHandler(item, statusItem.key)"
              >
                <v-list-item-title>{{ statusItem.value }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-chip
            v-else
            label
            small
            class="text-app-title"
            :color="jobsStatusColor(item.status)"
            dark
          >
            {{ jobsStatusText(item.status) }}
          </v-chip>
          <!--  -->
          <p
            :class="`ma-0 pa-0 font-weight-bold text-app-detail-large ${item.processPercentColor}`"
          >
            {{ item.progressPercent }}
          </p>

          <p class="ma-0 pa-0 text-app-normal">
            {{ item.checkpoint ? `${item.checkpoint} จุด` : "0 จุด" }} ({{
              item.totalDistance
            }})
          </p>
        </div>
      </template>

      <template v-slot:[`item.tools`]="{ item }">
        <!-- menu bar -->
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="handleDuplicateClick(item._id)"
              v-if="permList.jobs_crud || permList.admin_all"
              :disabled="!item.canDuplicate"
            >
              <v-list-item-icon>
                <v-icon> mdi-content-copy</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-app-title max-v-list-height">
                สร้างงานซ้ำ
              </v-list-item-title>
            </v-list-item>
            <!--  -->
            <v-list-item
              @click="openPenalityTab(item)"
              v-if="permList.user_penality || permList.admin_all"
            >
              <v-list-item-icon>
                <v-icon> mdi-account-details </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-app-title">
                พฤติกรรม</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              @click="handleSchedule([tableRows[item.rowIdx]], false)"
              v-if="permList.user_penality || permList.admin_all"
            >
              <v-list-item-icon>
                <v-icon> mdi-bird </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-app-title">
                มุมมองด้านบน</v-list-item-title
              >
            </v-list-item>
            <!--  -->
            <v-list-item
              @click="handleDeleteClick(item._id)"
              v-if="permList.jobs_crud || permList.admin_all"
            >
              <v-list-item-icon>
                <v-icon> mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-app-title">
                ลบข้อมูล
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:footer.page-text>
        <span></span>
      </template>
    </v-data-table>
    <AppDialogConfirm ref="confirmDelete" />
    <CheckFaceDialog
      :key="`cf${componentRefreshCounter}`"
      ref="checkFaceDialog"
    />
    <VerifyVehicleDialog
      :key="`vvd${componentRefreshCounter}`"
      ref="verifyVehicleDialog"
    />
    <DuplicateJobsDialog
      :key="`djd${componentRefreshCounter}`"
      ref="duplicateJobsDialog"
    />
    <EmployeeJobDialog ref="employeeJobDialogRef" />
    <!-- <JobStatusManageDialog
      :key="`jsm${componentRefreshCounter}`"
      ref="jobStatusManageDialog"
    /> -->
  </v-container>
</template>

<script>
import CustomCardFormView from "@/components/CustomCardFormView.vue";
import JobsFilterStatus from "@/views/jobs/components/JobsFilterStatus.vue";
import EmployeeJobDialog from "@/components/EmployeeJobDialog.vue";
import NormalButton from "@/components/NormalButton.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import { mapGetters } from "vuex";
import {
  deleteJobService,
  getJobInfoForStatusService,
  getJobListService,
  putJobStatusService,
} from "@/services/api/jobs";
import { getRouteTemplateNameListByContractIdService } from "@/services/api/report";
import { processPermission } from "@/services/permissions";
import JobsFilterGroup from "@/views/jobs/components/JobsFilterGroup.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import { dateStringToDateFormat } from "@/services/appDate";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import {
  appEncodeURIComponentHelper,
  appDecodeSingleURIComponentHelper,
  appEncodeSingleURIComponentHelper,
} from "@/services/appStringHelpper";
import JobSearchingInput from "./components/JobsSearchingInput.vue";
import defaultImage from "@/assets/user.png";
import {
  processDistanceText,
  processDurationText,
} from "@/services/appFuncHelpper";
import { getContractRouteTitleListService } from "@/services/api/jobs";
import _ from "lodash";
import CheckFaceDialog from "./manageView/components/dialog/CheckFaceDialog.vue";
import VerifyVehicleDialog from "./manageView/components/dialog/VerifyVehicleDialog.vue";
import DuplicateJobsDialog from "./components/dialog/DuplicateJobsDialog.vue";
// import JobStatusManageDialog from "./components/dialog/JobStatusManageDialog.vue";
import { URL_EMPLOYEE_PENALTY } from "@/services/routerQueryPath";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
import CustomTimePickerInput from "@/components/CustomTimePickerInput.vue";
import { getEmployeeGroupListSelectService } from "@/services/api/employeeGroup";
import moment from "moment";
import JobContractSelectorInput from "@/views/jobs/manageView/components/jobDetail/JobContractSelectorInput.vue";
import { readonly } from "vue";
export default {
  name: "jobsList",
  components: {
    CustomCardFormView,
    NormalButton,
    PrimaryButton,
    JobsFilterGroup,
    AppDialogConfirm,
    JobSearchingInput,
    JobsFilterStatus,
    CheckFaceDialog,
    VerifyVehicleDialog,
    DuplicateJobsDialog,
    EmployeeJobDialog,
    CustomDateTypingPickerFormInput,
    CustomTimePickerInput,
    JobContractSelectorInput,
    // JobStatusManageDialog,
  },
  data() {
    return {
      componentRefreshCounter: 0,
      defaultPFImage: defaultImage,
      dataTableFooterProps,
      dataTableNoDataText,
      dataTableLoadingText,
      tableLoading: false,
      field_sort_by: null,
      serverItemsLength: 0,

      options: {},
      queryStringSearch: "",
      sortVal: null,
      itemsPerPage: 20,

      nextLastId: null,
      nextLastValue: null,

      previousPage: 0,
      pagingStack: [],

      dataList: [],
      statusFilterValue: "all",
      scheduleDate: null,

      contractRouteTitleList: [],
      contractList: [],
      selectedContract: null,

      routeItemList: [],
      routeLoading: false,
      selectedRouteItem: null,
      updateRouteKey: 1,

      // contractRoute: null,
      datePicker: null,
      inputStartTime: null,
      inputEndTime: null,
      driverGroup: null,
      driverGroupList: [],

      queryFilterList: {},
      queryStringSearch: "", // combin filter and search text
      querySearchAndFilterList: {}, // combine filter and search
      queryOptionsTable: {
        limit: 20, // itemsPerPage = limit
        by: "confirmdate", // key
        order: "desc", // asc or desc
        page: 1, // current page
      },
      sortVal: null,
      isToday: false,
      checkboxData: {
        scanface: 0,
        samevehicle: 0,
        driverstatus: 0,
      },
      statusDriverOptions: [
        { key: "true", value: "พร้อม" },
        { key: "false", value: "ไม่พร้อม" },
      ],

      isShowMenu: false,
      permList: {
        admin_all: false,
        user_penality: false,
        jobs_list: false,
        jobs_info: false,
        jobs_crud: false,
        jobs_multiple_crud: false,
        jobs_multiple_tracking: false,
        jobs_report: false,
        jobs_total_report: false,
      },
      editTextBtn: "แก้ไขข้อมูล",
      isPermissionCanEdit: false,
      isPermissionCanView: false,
      sortList: [
        {
          text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
          value: "createddate-asc",
        },
        {
          text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
          value: "createddate-desc",
        },
        {
          text: "วันที่แก้ไขข้อมูล เก่าสุด-ล่าสุด",
          value: "updateddate-asc",
        },
        {
          text: "วันที่แก้ไขข้อมูล ล่าสุด-เก่าสุด",
          value: "updateddate-desc",
        },
        //
        {
          text: "เวลายืนยันงาน (ระบบ) เรียงจาก เก่าสุด-ล่าสุด",
          value: "confirmdate-asc",
        },
        {
          text: "เวลายืนยันงาน (ระบบ) เรียงจาก ล่าสุด-เก่าสุด",
          value: "confirmdate-desc",
        },
        {
          text: "เวลาเริ่มงาน (ระบบ) เรียงจาก เก่าสุด-ล่าสุด",
          value: "startdate-asc",
        },
        {
          text: "เวลาเริ่มงาน (ระบบ) เรียงจาก ล่าสุด-เก่าสุด",
          value: "startdate-desc",
        },
        {
          text: "เวลาจบงาน (ระบบ) เรียงจาก เก่าสุด-ล่าสุด",
          value: "enddate-asc",
        },
        {
          text: "เวลาจบงาน (ระบบ) เรียงจาก ล่าสุด-เก่าสุด",
          value: "enddate-desc",
        },
        //
        {
          text: "เวลายืนยันงาน (จริง) เรียงจาก เก่าสุด-ล่าสุด",
          value: "actualconfirmdate-asc",
        },
        {
          text: "เวลายืนยันงาน (จริง) เรียงจาก ล่าสุด-เก่าสุด",
          value: "actualconfirmdate-desc",
        },
        //
        {
          text: "เวลาเริ่มงาน (จริง) เรียงจาก เก่าสุด-ล่าสุด",
          value: "actualstartdate-asc",
        },
        {
          text: "เวลาเริ่มงาน (จริง) เรียงจาก ล่าสุด-เก่าสุด",
          value: "actualstartdate-desc",
        },
        {
          text: "เวลาจบงาน (จริง) เรียงจาก เก่าสุด-ล่าสุด",
          value: "actualenddate-asc",
        },
        {
          text: "เวลาจบงาน (จริง) เรียงจาก ล่าสุด-เก่าสุด",
          value: "actualenddate-desc",
        },

        {
          text: "จำนวน Check Point เรียงจาก น้อย-มาก",
          value: "checkpoint-asc",
        },
        {
          text: "จำนวน Check Point เรียงจาก มาก-น้อย",
          value: "checkpoint-desc",
        },
        {
          text: "%ความสำเร็จ เรียงจาก น้อย-มาก",
          value: "progresspercent-asc",
        },
        {
          text: "%ความสำเร็จ เรียงจาก มาก-น้อย",
          value: "progresspercent-desc",
        },
      ],
      // status
      currentStatusList: [],
      currentStatusQueue: [],
      statusDisabledAll: false,
    };
  },
  computed: {
    ...mapGetters({
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusText: "jobs/jobsStatusText",
      vehicleContractStatusColor: "vehicle/vehicleContractStatusColor",
      vehicleContractStatusText: "vehicle/vehicleContractStatusText",
      vehicleLicenseTypeText: "vehicle/vehicleLicenseTypeText",
      vehicleLicenseTypeColor: "vehicle/vehicleLicenseTypeColor",
      vehicleTypeText: "vehicle/vehicleTypeText",
      vehicleTypeIcon: "vehicle/vehicleTypeIcon",
    }),
    tableHeaders() {
      let defaultdata = [
        {
          text: "รหัสงาน",
          align: "start",
          sortable: false,
          value: "jobCode",
          width: "7%",
        },
        {
          text: "ชื่องาน",
          align: "start",
          sortable: false,
          value: "name",
          width: "15%",
        },

        {
          text: "ผู้ขับ",
          align: "start",
          sortable: false,
          value: "driver",
          width: "15%",
        },
        {
          text: "รถ",
          align: "start",
          sortable: false,
          value: "vehicle",
          align: "left",
          width: "15%",
        },
        {
          text: "เวลายืนยันรับงาน",
          align: "start",
          sortable: false,
          value: "confirmDate",
          align: "left",
          width: "10%",
        },
        {
          text: "เวลาเริ่มงาน",
          align: "start",
          sortable: false,
          value: "startDate",
          width: "8%",
        },
        {
          text: "เวลาจบงาน",
          align: "start",
          sortable: false,
          value: "endDate",
          width: "8%",
        },

        { text: "สถานะ", value: "status", sortable: false, align: "left" },
      ];
      //
      if (this.isPermissionCanView || this.isPermissionCanEdit) {
        defaultdata.push({
          text: "",
          value: "tools",
          sortable: false,
          align: "center",
        });
      }
      //
      return defaultdata;
    },
    tableRows() {
      const processRowMatchVehicleDisplay = function (row) {
        let returnData = {
          status: -1, // faill
          selectVehicleClassName: null,
          actualVehicleClassName: null,
          selectVehicleClassText: "ระบบ",
          actualVehicleClassText: "ยืนยันจริง",
          // selectVehicleCheck: false,
          // actualVehicleCheck: false,
        };

        if (row["vehicleStatusMatched"] === true) {
          // select is ok
          returnData["selectVehicleClassText"] = "ระบบและจริงตรงกัน";
          returnData["status"] = 1;
        } else if (!_.isNil(row["verifiedVehicleId"])) {
          // selected
          const _vehicleId = _.get(row, "vehicle._id", null);
          if (row["verifiedVehicleId"] === _vehicleId) {
            returnData["status"] = 1;
            returnData["selectVehicleClassText"] = "เลือกระบบ";
          } else {
            returnData["status"] = 2;
            returnData["actualVehicleClassText"] = "เลือกจริง";
          }
        } else if (_.isNil(row["vehicle"]) && _.isNil(row["actualVehicle"])) {
          return returnData;
        } else if (!_.isNil(row["vehicle"]) && _.isNil(row["actualVehicle"])) {
          returnData["status"] = 3;
        } else if (_.isNil(row["vehicle"]) && !_.isNil(row["actualVehicle"])) {
          returnData["status"] = 4;
          returnData["actualVehicleClassName"] = " red--text";
        } else {
          returnData["status"] = 5; //
          returnData["selectVehicleClassName"] = "red--text";
          returnData["actualVehicleClassName"] = " red--text";
        }

        return returnData;
      };

      const procossRowDate = function (row, key) {
        if (_.isNil(row[key])) {
          return null;
        }
        return dateStringToDateFormat(row[key], {
          type: "datetime",
          returnThai: false,
          showSecond: false,
        });
      };

      const rows = this.dataList.map((row, rowIdx) => {
        // console.log(row);
        return {
          rowIdx: rowIdx,
          _id: row._id,
          jobCode: row.jobCode,
          name: row.name,
          contract: _.get(row, "contract", "-"),
          driver: row.driver,
          vehicle: row.vehicle,
          assigner: row.assigner,

          startDate: procossRowDate(row, "startDate"),
          endDate: procossRowDate(row, "endDate"),
          confirmDate: procossRowDate(row, "confirmDate"),

          endDateRaw: row.endDate,
          estimateEndDateRaw: row.estimateEndDate,

          actualStartDate: procossRowDate(row, "actualStartDate"),
          actualEndDate: procossRowDate(row, "actualEndDate"),
          actualConfirmDate: procossRowDate(row, "actualConfirmDate"),
          totalDistance: null,
          checkpoint: row.checkpoint,
          totalDistance: processDistanceText(row.totalDistance),
          totalDuration: processDurationText(row.totalDuration),
          progressPercent: _.isNil(row.progressPercent)
            ? 0
            : `${row.progressPercent.toFixed(0)}%`,
          processPercentColor: this.getPercentLevelColor(row),
          remainingjob: _.get(row, "remainingjob", "-"),
          vehicleStatusMatched: row.vehicleStatusMatched,
          actualVehicle: row.actualVehicle,
          vehicleMatched: processRowMatchVehicleDisplay(row),

          status: _.get(row, "status", "-"),
          driverFaceMatched: row.driverFaceMatched,
          driverFaceMatchedColor: this.getDriverFaceMatchedColor(row),
          driverFaceMatchedText: this.getDriverFaceMatchedText(row),
          driverStatus: _.get(row, "driverStatus", null),
          canDuplicate: _.get(row, "canDuplicate", false),
          tools: [],
          statusLoading: row.statusLoading,
          statusOpen: row.statusOpen,
          keyCounter: row.keyCounter,
          newEventAccess: row.newEventAccess,
        };
      });

      return rows;
    },
  },

  mounted() {
    this.checkUserPermissions();
    this.getFundData();

    // if (_.isNil(this.$route.query["dateround"])) {
    //   this.$route.query["dateround"] = moment().format("YYYY-MM-DD");
    //   this.isToday = true;
    // }

    this.processRouterQuery(this.$route.query);
  },

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    setRouterQuery(queryObj) {
      // encode
      const _queryObj = appEncodeURIComponentHelper(queryObj);
      this.$router
        .replace({
          path: this.$router.path,
          query: _queryObj,
        })
        .catch(() => {});
    },
    async getFundData() {
      //get contract list
      try {
        let responseContractData = await getContractRouteTitleListService(
          this.$store
        );
        if (responseContractData["code"] === 200) {
          let routeRaw = _.clone(responseContractData["data"]);
          let sortedContractRoute = routeRaw.sort((a, b) => {
            if (a.value < b.value) {
              return -1;
            }
            if (a.value > b.value) {
              return 1;
            }
            return 0;
          });
          this.contractRouteTitleList = sortedContractRoute;
        }
      } catch (error) {
        //
      }

      //get default route list
      try {
        const responseDriverGroupsData =
          await getEmployeeGroupListSelectService(this.$store);
        this.driverGroupList = [];
        if (responseDriverGroupsData["code"] === 200) {
          if (!_.isNil(responseDriverGroupsData["data"])) {
            this.driverGroupList = responseDriverGroupsData["data"];
          }
        } else {
          console.error("FAIL_TO_GET_USER_GROUPS");
        }
      } catch (error) {
        //
      }
    },
    processRouterQuery(_queryRoute) {
      // console.log("_queryRoute", _queryRoute);
      if (Object.keys(_queryRoute).length > 0) {
        Object.keys(_queryRoute).forEach((key) => {
          if (!_.isNil(_queryRoute[key])) {
            switch (key) {
              case "text":
              case "contractnumber":
                this.querySearchAndFilterList[key] =
                  appDecodeSingleURIComponentHelper(_queryRoute[key]);
                break;
              case "limit":
              case "page":
                try {
                  this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
                } catch (error) {
                  this.queryOptionsTable[key] = this.queryOptionsTable[key];
                }
                break;
              case "by":
              case "order":
                this.queryOptionsTable[key] = _queryRoute[key];
                break;
              case "route":
                // this.contractRoute = _queryRoute[key];
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
              case "drivergrp":
                this.driverGroup = _queryRoute[key];
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
              case "dateround":
                this.datePicker = _queryRoute[key];
                this.querySearchAndFilterList[key] = _queryRoute[key];
                console.log("dateround", _queryRoute[key]);

                let today = moment().format("YYYY-MM-DD");
                if (_queryRoute[key] === today) {
                  this.isToday = true;
                } else {
                  this.isToday = false;
                }
                break;
              case "starttimeround":
                let startTimeDecode = appDecodeSingleURIComponentHelper(
                  _queryRoute[key]
                );
                let startTimeDateDecode = `${moment().format(
                  "DD/MM/YYYY"
                )} ${startTimeDecode}`;

                this.inputStartTime = this.parseDate(startTimeDateDecode);
                this.querySearchAndFilterList[key] = startTimeDecode;
                break;
              case "endtimeround":
                let endTimeDecode = appDecodeSingleURIComponentHelper(
                  _queryRoute[key]
                );
                let endTimeDateDecode = `${moment().format(
                  "DD/MM/YYYY"
                )} ${endTimeDecode}`;

                this.inputEndTime = this.parseDate(endTimeDateDecode);
                this.querySearchAndFilterList[key] = endTimeDecode;
                break;
              case "scanface":
              case "samevehicle":
              case "driverstatus":
                this.checkboxData[key] = _queryRoute[key] === "1" ? 1 : 0;
                if (this.checkboxData[key] === 1) {
                  this.querySearchAndFilterList[key] = 1;
                }
                break;
              default:
                // filter and search
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
            }
          }
        });

        this.querySearchAndFilterList = {
          ...this.queryOptionsTable,
          ...this.querySearchAndFilterList,
        };
      } else {
        // set default parameter
        this.querySearchAndFilterList = {
          ...this.queryOptionsTable,
          status: "all",
        };
      }
      //
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      // this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },

    //
    processStatus(newStatusVal) {
      console.log("processStatus");

      this.statusGroupDisabled = true;
      this.statusFilterValue = newStatusVal;
      if (_.isNil(this.statusFilterValue)) {
        // remove from list
        delete this.querySearchAndFilterList["status"];
        return;
      }

      this.processSearchingParams({
        status: this.statusFilterValue.toLowerCase(),
      });

      this.resetToFirstPage();

      if (this.queryOptionsTable["page"] === 1) {
        // call getData() right HERE
        this.setRouterQuery(this.querySearchAndFilterList);
        this.getData();
      } else {
        // call getData() at tableUpdateHandler()
        this.querySearchAndFilterList["page"] = 1;
        this.queryOptionsTable["page"] = 1;
      }
    },

    handleToday() {
      if (this.isToday) {
        this.datePicker = moment().format("YYYY-MM-DD");
        this.tableDatePickerChangeHandler(this.datePicker);
      } else {
        this.datePicker = null;
        this.tableDatePickerChangeHandler(this.datePicker);
      }
    },

    changeValueOfCheckboxStatus() {
      let searchObj = {};
      Object.keys(this.checkboxData).forEach((key) => {
        if (this.checkboxData[key]) {
          searchObj[key] = "1";
        } else {
          delete this.querySearchAndFilterList[key];
        }
      });

      this.processSearchingParams(searchObj);

      this.resetToFirstPage();

      if (this.queryOptionsTable["page"] === 1) {
        // call getData() right HERE
        this.setRouterQuery(this.querySearchAndFilterList);
        this.getData();
      } else {
        // call getData() at tableUpdateHandler()
        this.querySearchAndFilterList["page"] = 1;
        this.queryOptionsTable["page"] = 1;
      }
    },

    async getData() {
      this.tableLoading = true;
      this.dataList = [];

      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();

      try {
        let responseData = await getJobListService(
          this.$store,
          this.queryStringSearch
        );
        if (responseData.code == 200) {
          this.serverItemsLength = responseData.data["total"];
          //
          for (let i = 0; i < responseData.data["jobList"].length; i++) {
            responseData.data["jobList"][i]["statusLoading"] = false;
            responseData.data["jobList"][i]["statusOpen"] = false;
            responseData.data["jobList"][i]["keyCounter"] = 0;
          }
          this.dataList = responseData.data["jobList"];

          this.pagingStack.push({
            nextLastId: responseData.data["nextLastId"],
            nextLastValue: responseData.data["nextLastValue"],
          });

          // console.log("pagingStack", this.pagingStack);
        }
      } catch (error) {
        console.log(error);
      }

      this.tableLoading = false;
    },

    processSingleJobDateSchedule(item) {
      this.scheduleDate = null;
      if (!_.isNil(item[0])) {
        if (!_.isNil(item[0]["endDate"])) {
          this.scheduleDate = {
            startDate: moment(item[0]["endDateRaw"])
              .add(1, "days")
              .format("YYYY-MM-DD"),
          };
        } else if (!_.isNil(item[0]["estimateEndDateRaw"])) {
          this.scheduleDate = {
            startDate: moment(item[0]["estimateEndDateRaw"])
              .add(1, "days")
              .format("YYYY-MM-DD"),
          };
        }
      }
    },

    processDateSchedule() {
      this.scheduleDate = null;
      if (Object.keys(this.queryFilterList).length !== 0) {
        if (this.queryFilterList["minconfirmdate"]) {
          if (this.queryFilterList["maxconfirmdate"]) {
            this.scheduleDate = {
              startDate: this.queryFilterList["minconfirmdate"],
              endDate: this.queryFilterList["maxconfirmdate"],
            };
          } else {
            this.scheduleDate = {
              startDate: this.queryFilterList["minconfirmdate"],
            };
          }
        } else if (this.queryFilterList["minstartdate"]) {
          if (this.queryFilterList["maxstartdate"]) {
            this.scheduleDate = {
              startDate: this.queryFilterList["minstartdate"],
              endDate: this.queryFilterList["maxstartdate"],
            };
          } else {
            this.scheduleDate = {
              startDate: this.queryFilterList["minstartdate"],
            };
          }
        } else if (this.queryFilterList["minenddate"]) {
          if (this.queryFilterList["maxenddate"]) {
            this.scheduleDate = {
              startDate: this.queryFilterList["minenddate"],
              endDate: this.queryFilterList["maxenddate"],
            };
          } else {
            this.scheduleDate = {
              startDate: this.queryFilterList["minenddate"],
            };
          }
        } else if (this.queryFilterList["minactualconfirmdate"]) {
          if (this.queryFilterList["maxactualconfirmdate"]) {
            this.scheduleDate = {
              startDate: this.queryFilterList["minactualconfirmdate"],
              endDate: this.queryFilterList["maxactualconfirmdate"],
            };
          } else {
            this.scheduleDate = {
              startDate: this.queryFilterList["minactualconfirmdate"],
            };
          }
        } else if (this.queryFilterList["minactualstartdate"]) {
          if (this.queryFilterList["maxactualstartdate"]) {
            this.scheduleDate = {
              startDate: this.queryFilterList["minactualstartdate"],
              endDate: this.queryFilterList["maxactualstartdate"],
            };
          } else {
            this.scheduleDate = {
              startDate: this.queryFilterList["minactualstartdate"],
            };
          }
        } else if (this.queryFilterList["minactualenddate"]) {
          if (this.queryFilterList["maxactualenddate"]) {
            this.scheduleDate = {
              startDate: this.queryFilterList["minactualenddate"],
              endDate: this.queryFilterList["maxactualenddate"],
            };
          } else {
            this.scheduleDate = {
              startDate: this.queryFilterList["minactualenddate"],
            };
          }
        }
      }

      // console.log("scheduleDate", this.scheduleDate);
    },

    // tableContractRouteChangeHandler(value) {
    //   if (_.isNil(value)) {
    //     // remove from list
    //     delete this.querySearchAndFilterList["route"];
    //   } else {
    //     this.processSearchingParams({
    //       route: value["key"],
    //     });
    //   }
    //   this.setRouterQuery(this.querySearchAndFilterList);
    //   this.getData();
    // },
    tableContractChangeHandler(value) {
      // console.log("tableContractChangeHandler");
      if (_.isNil(value)) {
        delete this.querySearchAndFilterList["contractnumber"];
        delete this.querySearchAndFilterList["route"];
      } else {
        delete this.querySearchAndFilterList["route"];
        this.processSearchingParams({
          contractnumber: value["contractNumber"],
        });
        this.getRouteListByContractId(value["key"]);
      }

      this.resetToFirstPage();

      if (this.queryOptionsTable["page"] === 1) {
        // call getData() right HERE
        this.setRouterQuery(this.querySearchAndFilterList);
        this.getData();
      } else {
        // call getData() at tableUpdateHandler()
        this.querySearchAndFilterList["page"] = 1;
        this.queryOptionsTable["page"] = 1;
      }

      // this.setRouterQuery(this.querySearchAndFilterList);
      // this.getData();
    },
    async getRouteListByContractId(contractId) {
      this.routeLoading = true;
      this.routeItemList = [];
      const queryString = new URLSearchParams({
        id: contractId,
      }).toString();

      try {
        const rsp = await getRouteTemplateNameListByContractIdService(
          this.$store,
          queryString
        );
        if (rsp["code"] === 200) {
          this.routeItemList = rsp["data"];
        } else {
          throw "FETCH_ERROR";
        }
      } catch (error) {
        console.error(error);
      }

      this.routeLoading = false;
      this.updateRouteKey += 1;
      // if url query string have route
      const _routeId = this.querySearchAndFilterList["route"] || null;
      if (!_.isNil(_routeId)) {
        const selectedItems = _.filter(
          this.routeItemList,
          (item) => item["_id"] === _routeId
        );

        if (selectedItems.length > 0) {
          this.selectedRouteItem = selectedItems[0];
        }
      }
    },
    JobContractSelectorInputItems(newItems) {
      if (newItems.length > 0) {
        const contractNumber = this.querySearchAndFilterList["contractnumber"];

        const selectedItems = _.filter(
          newItems,
          (item) => item["contractNumber"] === contractNumber
        );

        if (selectedItems.length > 0) {
          this.selectedContract = selectedItems[0];
          this.getRouteListByContractId(selectedItems[0]["key"]);
        }
      }
    },
    tableContractRouteItemChangeHandler(value) {
      if (_.isNil(value)) {
        delete this.querySearchAndFilterList["route"];
      } else {
        this.processSearchingParams({
          route: value["_id"],
        });
      }

      this.resetToFirstPage();

      if (this.queryOptionsTable["page"] === 1) {
        // call getData() right HERE
        this.setRouterQuery(this.querySearchAndFilterList);
        this.getData();
      } else {
        // call getData() at tableUpdateHandler()
        this.querySearchAndFilterList["page"] = 1;
        this.queryOptionsTable["page"] = 1;
      }

      // this.setRouterQuery(this.querySearchAndFilterList);
      // this.getData();
    },

    tableGroupChangeHandler(value) {
      if (_.isNil(value)) {
        delete this.querySearchAndFilterList["drivergrp"];
      } else {
        this.processSearchingParams({
          drivergrp: value["_id"],
        });
      }

      this.resetToFirstPage();

      if (this.queryOptionsTable["page"] === 1) {
        // call getData() right HERE
        this.setRouterQuery(this.querySearchAndFilterList);
        this.getData();
      } else {
        // call getData() at tableUpdateHandler()
        this.querySearchAndFilterList["page"] = 1;
        this.queryOptionsTable["page"] = 1;
      }

      // this.setRouterQuery(this.querySearchAndFilterList);
      // this.getData();
    },

    tableDatePickerChangeHandler(value) {
      let today = moment().format("YYYY-MM-DD");
      if (value === today) {
        this.isToday = true;
      } else {
        this.isToday = false;
      }

      if (_.isNil(value)) {
        delete this.querySearchAndFilterList["dateround"];
      } else {
        this.querySearchAndFilterList["dateround"] = value;
        this.processSearchingParams({
          dateround: value,
        });
      }

      this.resetToFirstPage();

      if (this.queryOptionsTable["page"] === 1) {
        // call getData() right HERE
        this.setRouterQuery(this.querySearchAndFilterList);
        this.getData();
      } else {
        // call getData() at tableUpdateHandler()
        this.querySearchAndFilterList["page"] = 1;
        this.queryOptionsTable["page"] = 1;
      }

      // this.querySearchAndFilterList["page"] = 1;
      // this.queryOptionsTable["page"] = 1;

      // this.setRouterQuery(this.querySearchAndFilterList);
      // this.getData();
    },

    tableStartTimeChangeHandler(value) {
      if (_.isNil(value)) {
        if (this.querySearchAndFilterList["starttimeround"] === undefined) {
          return;
        }
        delete this.querySearchAndFilterList["starttimeround"];
      } else {
        let startTime = moment(value).format("HH:mm");
        if (this.querySearchAndFilterList["starttimeround"] === startTime) {
          return;
        }
        this.processDataToUpdate(value, "StartTime");
        this.processSearchingParams({
          starttimeround: startTime,
        });
      }

      this.resetToFirstPage();

      if (this.queryOptionsTable["page"] === 1) {
        // call getData() right HERE
        this.setRouterQuery(this.querySearchAndFilterList);
        this.getData();
      } else {
        // call getData() at tableUpdateHandler()
        this.querySearchAndFilterList["page"] = 1;
        this.queryOptionsTable["page"] = 1;
      }

      // this.setRouterQuery(this.querySearchAndFilterList);
      // this.getData();
    },
    tableEndTimeChangeHandler(value) {
      if (_.isNil(value)) {
        if (this.querySearchAndFilterList["endtimeround"] === undefined) {
          return;
        }
        delete this.querySearchAndFilterList["endtimeround"];
      } else {
        let endTime = moment(value).format("HH:mm");
        if (this.querySearchAndFilterList["endtimeround"] === endTime) {
          return;
        }
        this.processDataToUpdate(value, "EndTime");
        this.processSearchingParams({
          endtimeround: endTime,
        });
      }
      this.resetToFirstPage();

      if (this.queryOptionsTable["page"] === 1) {
        // call getData() right HERE
        this.setRouterQuery(this.querySearchAndFilterList);
        this.getData();
      } else {
        // call getData() at tableUpdateHandler()
        this.querySearchAndFilterList["page"] = 1;
        this.queryOptionsTable["page"] = 1;
      }

      // this.setRouterQuery(this.querySearchAndFilterList);
      // this.getData();
    },

    processDataToUpdate(newValue, assignAtValue) {
      try {
        const getHourAndMinute = function (m) {
          return m.minutes() + m.hours() * 60;
        };

        if (assignAtValue === "StartTime") {
          this.inputStartTime = newValue;
          if (!_.isNil(this.inputStartTime)) {
            const startTime = moment(this.inputStartTime);
            const endTime = moment(this.inputEndTime);
            if (getHourAndMinute(startTime) > getHourAndMinute(endTime)) {
              this.inputEndTime = null;
            } else {
              // not do thing
            }
          }
        } else {
          this.inputEndTime = newValue;
          if (!_.isNil(this.inputEndTime)) {
            const startTime = moment(this.inputStartTime);
            const endTime = moment(this.inputEndTime);
            if (getHourAndMinute(startTime) > getHourAndMinute(endTime)) {
              this.inputStartTime = null;
            } else {
              // not do thing
            }
          }
        }
        //
      } catch (error) {
        console.log(error);
      }
    },

    async handleSchedule(jobList, isMultiple) {
      if (!_.isNil(jobList)) {
        let initedEmpList = [];
        jobList.forEach((employee) => {
          if (
            !_.isNil(employee["driver"]) &&
            employee["driver"] !== undefined
          ) {
            let driver = employee["driver"];
            let fullName = `${
              driver["nickName"] ? driver["nickName"]["th"] : ""
            } ${driver["firstName"] ? driver["firstName"]["th"] : ""} ${
              driver["lastName"] ? driver["lastName"]["th"] : ""
            } ${driver["empId"] ? "(" + driver["empId"] + ")" : ""}`;

            if (!initedEmpList.some((e) => e.key === driver["_id"])) {
              initedEmpList.push({
                key: driver["_id"],
                value: fullName,
              });
            }
          }
        });

        if (isMultiple) {
          this.processDateSchedule();
        } else {
          this.processSingleJobDateSchedule(jobList);
        }
        let empJob = null;
        empJob = await this.$refs.employeeJobDialogRef.open(
          initedEmpList,
          this.scheduleDate
        );

        // if (initedEmpList.length > 0) {
        //   empJob = await this.$refs.employeeJobDialogRef.open(
        //     initedEmpList,
        //     this.scheduleDate
        //   );
        // } else {
        //   empJob = await this.$refs.employeeJobDialogRef.open();
        // }
      }
    },

    resetToFirstPage() {
      delete this.querySearchAndFilterList["nextLastId"];
      delete this.querySearchAndFilterList["nextLastValue"];
      delete this.queryOptionsTable["nextLastId"];
      delete this.queryOptionsTable["nextLastValue"];
      this.previousPage = 0;
      this.pagingStack = [];
    },

    getPagingHander(currentPage) {
      if (currentPage > this.previousPage + 1) {
        // Next Page
        this.queryOptionsTable["nextLastId"] =
          this.pagingStack[this.pagingStack.length - 1]["nextLastId"];
        this.queryOptionsTable["nextLastValue"] =
          this.pagingStack[this.pagingStack.length - 1]["nextLastValue"];
        this.previousPage = currentPage - 1;
      } else {
        // Previous Page
        if (this.pagingStack.length - 2 > 0) {
          this.queryOptionsTable["nextLastId"] =
            this.pagingStack[this.pagingStack.length - 3]["nextLastId"];
          this.queryOptionsTable["nextLastValue"] =
            this.pagingStack[this.pagingStack.length - 3]["nextLastValue"];
          this.previousPage = currentPage - 1;
          this.pagingStack.splice(-2, 2);
        } else {
          // 1st Page
          this.resetToFirstPage();
        }
      }
    },

    tableUpdateHandler(type, value) {
      console.log("tableUpdateHandler:", type, value);
      switch (type) {
        case "sort":
          let _fieldSortBy = value.split("-");
          this.queryOptionsTable["by"] = [_fieldSortBy[0]];
          this.queryOptionsTable["order"] = [_fieldSortBy[1]];
          this.queryOptionsTable["page"] = 1;
          this.resetToFirstPage();
          break;
        case "limit":
          this.queryOptionsTable["limit"] = value;
          this.queryOptionsTable["page"] = 1;
          this.resetToFirstPage();
          break;
        case "page":
          this.queryOptionsTable["page"] = value;
          this.getPagingHander(value);
          break;
        default:
          this.queryOptionsTable[type] = value;
          break;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    tablePageHandler(value) {
      this.tableUpdateHandler("page", value);
    },
    tableItemPerPageHandler(value) {
      this.tableUpdateHandler("limit", value);
    },
    tableSortHandler(value) {
      this.tableUpdateHandler("sort", value);
    },
    //
    // search method scope
    //
    processSearchingParams(_queryParameter) {
      // combine query search
      this.querySearchAndFilterList = {
        ...this.queryOptionsTable, // default table
        ...this.querySearchAndFilterList, // old query data
        ..._queryParameter,
      };

      // this.querySearchAndFilterList["page"] = 1;
      // this.queryOptionsTable["page"] = 1;
      // this.resetToFirstPage();

      this.setRouterQuery(this.querySearchAndFilterList);
    },
    getSearchItem(_searchingParameter) {
      //
      // callback from searching component when enter
      //
      this.queryStringSearchList = {};
      delete this.querySearchAndFilterList["fieldid"]; // id
      delete this.querySearchAndFilterList["text"];
      let _queryParameter = {};
      if (!_.isNil(_searchingParameter)) {
        if (!_.isUndefined(_searchingParameter["key"])) {
          _queryParameter["fieldid"] = _searchingParameter.key;
          _queryParameter["text"] = _searchingParameter.value;
        } else {
          if (!_.isNil(_searchingParameter.value)) {
            _queryParameter["text"] = _searchingParameter.value;
          }
        }
      }

      this.processSearchingParams(_queryParameter);

      this.resetToFirstPage();

      if (this.queryOptionsTable["page"] === 1) {
        // call getData() right HERE
        this.setRouterQuery(this.querySearchAndFilterList);
        this.getData();
      } else {
        // call getData() at tableUpdateHandler()
        this.querySearchAndFilterList["page"] = 1;
        this.queryOptionsTable["page"] = 1;
      }

      // this.getData();

      // if (Object.keys(_queryParameter).length !== 0) {
      //   this.processSearchingParams(_queryParameter);
      //   this.getData();
      // }
    },
    getfilterItem(_queryParameter) {
      //
      // callback from filter group
      //
      if (!_.isEmpty(_queryParameter)) {
        Object.keys(_queryParameter).forEach((key) => {
          delete this.querySearchAndFilterList[key];
        });
      }

      this.queryFilterList = _queryParameter;

      if (Object.keys(_queryParameter).length !== 0) {
        this.processSearchingParams(_queryParameter);

        this.resetToFirstPage();

        if (this.queryOptionsTable["page"] === 1) {
          // call getData() right HERE
          this.setRouterQuery(this.querySearchAndFilterList);
          this.getData();
        } else {
          // call getData() at tableUpdateHandler()
          this.querySearchAndFilterList["page"] = 1;
          this.queryOptionsTable["page"] = 1;
        }

        // this.getData();
      }
    },

    parseDate(newValue) {
      const _d = moment(newValue, "DD/MM/YYYY HH:mm");
      return _d.format("YYYY-MM-DDTHH:mm:ss");
    },

    getDriverStatusText(status) {
      if (!_.isNil(status)) {
        if (status) {
          return "พร้อม";
        }
        return "ไม่พร้อม";
      }
      return "ยังไม่ตรวจ";
    },
    getDriverStatusColor(status) {
      if (!_.isNil(status)) {
        if (status) {
          return "success--text";
        }
        return "error--text";
      }
      return "warning--text";
    },
    getDriverFaceMatchedColor(row) {
      if (_.isNil(row["driverFaceMatched"])) {
        return "";
      } else if (row["driverFaceMatched"]) {
        return "green--text";
      } else {
        return "red--text";
      }
    },
    getDriverFaceMatchedText(row) {
      if (_.isNil(row["driverFaceMatched"])) {
        return "ยังไม่ตรวจ";
      } else if (row["driverFaceMatched"]) {
        return "ผ่าน";
      } else {
        return "ไม่ผ่าน";
      }
    },
    getPercentLevelColor(row) {
      if (row["progressPercent"] >= 100) {
        return "green--text text--darken-2";
      } else if (row["progressPercent"] >= 75) {
        return "green--text text--darken-1";
      } else if (row["progressPercent"] >= 50) {
        return "green--text text--lighten-1";
      } else if (row["progressPercent"] >= 25) {
        return "green--text text--lighten-2";
      } else {
        return "green--text text--lighten-3";
      }
    },
    //
    handleAddClick() {
      this.$router.push("/caj/jobs/create");
    },
    handleEditClick(_id) {
      this.$router.push(`/caj/jobs/edit/${_id}`);
    },
    async handleDuplicateClick(_id) {
      const duplicateStatus = await this.$refs.duplicateJobsDialog.open(_id);
      if (duplicateStatus === true) {
        this.getData();
      }
    },
    reset() {
      if (this.queryStringSearch.length !== 0) {
        this.componentRefreshCounter += 1;
      }

      // this.contractRoute = null;
      this.datePicker = null;
      this.inputStartTime = null;
      this.inputEndTime = null;
      this.driverGroup = null;
      this.selectedContract = null;
      this.selectedRouteItem = null;
      this.routeItemList = [];
      this.updateRouteKey += 1;

      this.resetToFirstPage();

      this.queryOptionsTable = {
        limit: 20, // itemsPerPage = limit
        by: "confirmdate", // key
        order: "desc", // asc or desc
        page: 1, // current page
      };
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.querySearchAndFilterList = { status: "all" };

      this.processSearchingParams();
      // this.getData();
    },

    async handleDeleteClick(_id) {
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการยกเลิกงานนี้ ?",
          null,
          DialogType.ACTION,
          {
            noconfirm: true,
          }
        )
      ) {
        await deleteJobService(this.$store, _id);
        this.getData();
      }
    },
    //
    async openCheckFaceDialog(row) {
      const dialogResponseData = await this.$refs.checkFaceDialog.open(
        row,
        true
      );
      if (dialogResponseData !== null) {
        let currentData = _.cloneDeep(this.dataList);
        for (let i = 0; i < dialogResponseData["jobs"].length; i++) {
          for (let j = 0; j < this.dataList.length; j++) {
            if (dialogResponseData["jobs"][i] === this.dataList[j]["_id"]) {
              currentData[j]["driverFaceMatched"] =
                dialogResponseData["driverFaceMatched"];
              currentData[j]["driverStatus"] =
                dialogResponseData["driverStatus"];

              break;
            }
          }
        }
        //
        this.dataList = _.cloneDeep(currentData);
      }
      this.componentRefreshCounter += 1;
    },
    async openVerifyVehicle(row) {
      const dialogResponseData = await this.$refs.verifyVehicleDialog.open(
        row,
        true
      );
      if (dialogResponseData !== null) {
        this.dataList[row["rowIdx"]]["verifiedVehicleId"] =
          dialogResponseData["verifiedVehicleId"];
      }
      this.componentRefreshCounter += 1;
    },
    // status list
    async getStatusListMenuChange(evt) {
      if (this.currentStatusQueue.length > 0) {
        this.dataList[this.currentStatusQueue[0]]["statusOpen"] = evt;
        this.dataList[this.currentStatusQueue[0]]["statusLoading"] = false;
        this.currentStatusQueue.shift();
      }
    },
    async getStatusListHandler(row) {
      this.statusDisabledAll = true;
      this.currentStatusQueue.push(row.rowIdx);
      this.dataList[row.rowIdx]["statusLoading"] = true;
      this.currentStatusList = [];
      try {
        const responseData = await getJobInfoForStatusService(
          this.$store,
          `id=${row._id}`
        );

        this.dataList[row.rowIdx]["statusLoading"] = false;
        this.dataList[row.rowIdx]["statusOpen"] = true;

        if (responseData["code"] === 200) {
          if (responseData["data"]["statusList"].length > 0) {
            this.currentStatusList = responseData["data"]["statusList"];
          }
        } else {
          throw "API_STATUS_FETCH_FAIL";
        }
      } catch (error) {
        this.dataList[row.rowIdx]["statusLoading"] = false;
      }
      this.statusDisabledAll = false;
    },
    async selectStatusListHandler(row, selectedItem) {
      this.dataList[row.rowIdx]["statusLoading"] = true;
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการเปลี่ยนสถานะงานนี้ ?",
          null,
          DialogType.ACTION,
          {
            noconfirm: true,
          }
        )
      ) {
        this.currentStatusQueue.shift();
        this.dataList[row.rowIdx]["statusOpen"] = false;
        try {
          const responseData = await putJobStatusService(
            this.$store,
            `id=${row._id}`,
            { status: selectedItem }
          );

          if (responseData["code"] === 200) {
            if (responseData["data"]["status"] === 1) {
              this.dataList[row.rowIdx]["status"] = selectedItem;
            }
          } else {
            throw "API_STATUS_FETCH_FAIL";
          }
        } catch (error) {
          console.error(error);
        }
      }

      this.dataList[row.rowIdx]["statusLoading"] = false;
    },
    getExpiredColor(period) {
      if (period.years == 0 && period.months == 0) {
        if (period.days < 3) {
          return "red--text text--darken-1 font-weight-bold";
        } else if (period.days < 10) {
          return "amber--text text--darken-3 font-weight-bold";
        }
      }
      return "green--text text--darken-1 font-weight-bold";
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "JOBS");

        if (this.permList.admin_all === true) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
          this.editTextBtn = "แก้ไขข้อมูล";
          return;
        }

        if (this.permList.jobs_info) {
          this.isPermissionCanView = true;
          this.editTextBtn = "ดูข้อมูล";
        }

        if (this.permList.jobs_crud) {
          this.isPermissionCanEdit = true;
          this.editTextBtn = "แก้ไขข้อมูล";
        }
      }
    },
    openPenalityTab(item) {
      const params = new URLSearchParams({
        job: item["_id"],
        cjvtext: appEncodeSingleURIComponentHelper(
          `งาน ${item["jobCode"]} ${item["name"]}`
        ),
      }).toString();

      const goTo = `${window.location.origin}/employee${URL_EMPLOYEE_PENALTY}&${params}`;
      window.open(goTo, "_blank");
    },
  },
};
</script>
<style scoped>
.max-v-list-height {
  line-height: 20px;
}
</style>
